<template>
  <div id="notification" class="pa-4 pl-0">
    <!-- <v-container fluid> -->
    <h2>Notification from Potential Clients</h2>
    <template v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          color="#ff5722"
          size="64"
          class="ma-4"
        ></v-progress-circular>
        <div>
          <span>Loading...</span>
        </div>
      </div>
    </template>

    <template v-else>
      <v-list v-if="items.length > 0" three-line class="n-notification">
        <template v-for="(item, index) in items">
          <div :key="index">
            <v-subheader v-if="item.header" v-text="item.header" />
            <v-list-item>
              <v-list-item-content style="margin-bottom: -25px">
                <v-list-item-title
                  style="margin-bottom: 3px"
                  v-html="'<b>From: </b>' + item.user_name"
                />
                <v-list-item-title
                  style="white-space: normal !important"
                  v-html="
                    '<b>' +
                    item.user_name +
                    '</b><span> has shown interest in your video titled <b>' +
                    item.title +
                    '</b>. ' +
                    item.user_name +
                    ' can be contacted at <b>' +
                    item.vewerId +
                    '</b></span>'
                  "
                >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />
          </div>
        </template>
      </v-list>
      <div v-else style="text-align: center">No notifications available</div>
    </template>
    <!-- </v-row> -->
    <!-- </v-container> -->
    <v-snackbar v-model="snackbar">
      Video deleted successfully
      <v-btn color="white" text @click="snackbar = false" icon>
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import VideoService from "@/services/VideoService";
// import moment from "moment";
//import axios from 'axios';
export default {
  data: () => ({
    loading: false,
    deleteBtnLoading: false,
    snackbar: false,
    dialogDelete: false,
    tab: null,
    items: [],
  }),
  methods: {
    async getNotification() {
      this.loading = true;
      const notification = await VideoService.getNotification({
        page: this.page,
      })
        .catch((err) => {
          console.log(err);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
      if (!notification) return;
      this.items = notification.data.Items;
    },
  },

  mounted() {
    this.getNotification();
  },
  beforeRouteUpdate(to, from, next) {
    this.getNotification();
    next();
  },
  // computed: {
  //   visiblePages () {
  //     return this.pages.slice((this.page - 1)* this.perPage, this.page* this.perPage)
  //   }
  // }
};
</script>

<style lang="scss">
.n-notification .v-list-item__title:nth-child(1) {
  color: #ff5722;
}
</style>

<style scoped>
.v-list-item__title {
  text-transform: none;
}
.text-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the height of the container to occupy full viewport height */
}
</style>
