<template>
  <div id="notification" class="pa-4 pl-0">
    <!-- <v-container fluid> -->

    <template v-if="loading">
      <div class="text-center-loading">
        <v-progress-circular
          indeterminate
          color="#ff5722"
          size="64"
          class="ma-4"
        ></v-progress-circular>
        <div>
          <span>Loading...</span>
        </div>
      </div>
    </template>

    <template>
      <v-container fluid>
        <h2>My Properties</h2>
        <template v-for="(item, index) in videos">
          <v-list-item :key="index">
            <div class="row mb-4 mt-4">
              <div class="col-md-4 col-sm-12 text-center">
                <v-list-item-title :height="100" :width="100">
                  <v-responsive
                    style="border-radius: 10px"
                    class="video-container"
                  >
                    <!-- <v-carousel
                    v-if="item.Gallery"
                    :continuous="false"
                    hide-delimiters
                    class="carousel-container"
                  > -->
                    <!-- <v-carousel-item
                      v-for="(video, index) in item.Gallery?.Videos"
                      :key="index"
                    > -->

                    <div
                      v-if="item.Gallery?.Videos && item.Gallery?.Videos.length"
                      class="myvideo"
                    >
                      <video-player
                        :options="
                          getVideoOptions(item.Gallery?.Videos[0], item)
                        "
                        class="video-player-container"
                      >
                        <video
                          class="video-player-container"
                          width="100"
                          preload="metadata"
                          crossorigin
                          controls="controls"
                          playsinline
                          :options="options"
                        ></video>
                      </video-player>
                    </div>
                    <div v-else class="video-player-container">
                      <v-img
                        cover
                        :src="require('../../assets/no-video.webp')"
                      ></v-img>
                    </div>
                    <!-- </v-carousel-item> -->
                    <!-- <v-carousel-item
                      v-for="(image, index) in item.Gallery?.Images"
                      :key="index"
                    >
                      <v-img v-if="image" :src="image"></v-img>
                      <v-img
                        v-else
                        :src="require('../../assets/no-video.webp')"
                      ></v-img>
                    </v-carousel-item>
                    <v-btn
                      v-if="!isFirstVideo && !isVideoPlaying"
                      class="custom-prev-button video-player-container"
                      icon
                      @click="prevCarousel"
                    >
                      <img :src="require('../../assets/previous-button.png')" />
                    </v-btn>
                    <v-btn
                      v-if="!isLastVideo && !isVideoPlaying"
                      class="custom-next-button video-player-container"
                      icon
                      @click="nextCarousel"
                    >
                      <img :src="require('../../assets/next-button.png')" />
                    </v-btn>
                  </v-carousel>
                  <v-carousel
                    v-else
                    :continuous="false"
                    hide-delimiters
                    class="carousel-container"
                  >
                    <v-carousel-item>
                      <v-img
                        height="100%"
                        cover
                        :src="require('../../assets/no-video.webp')"
                      ></v-img>
                    </v-carousel-item>
                  </v-carousel> -->
                  </v-responsive>
                  <!-- <v-responsive>
                  <vue-plyr v-if="item.Gallery?.Videos">
                    <video controls crossorigin playsinline>
                      <source
                      :data-poster="item.Gallery.Images[0]"
                        :id="item.createTimeStamp"
                        :src="item.Gallery?.Videos[0]"
                        type="video/mp4"
                      />
                    </video>
                  </vue-plyr>
                  <v-img
                    v-else
                    height="100%"
                    cover
                    :src="require('../../assets/no-video.webp')"
                  ></v-img>
                </v-responsive> -->
                </v-list-item-title>
              </div>
              <div class="col-md-8 col-sm-12">
                <v-list-item-content class="mt-0 pt-0">
                  <!-- <v-list-item-title>
                  <div class="text--primary mt-0 text-body-2">
                    Posted By :
                    <span class="grey--text text--lighten-0">
                      {{ item.user_name }}
                    </span>
                  </div>
                </v-list-item-title> -->
                  <v-list-item-title>
                    <div class="text--primary mt-2 text-body-2">
                      Title :
                      <span class="grey--text text--lighten-0">{{
                        item.title
                      }}</span>
                    </div>
                  </v-list-item-title>
                  <v-list-item-title>
                    <div class="text--primary mt-2 text-body-2">
                      Location :
                      <span class="grey--text text--lighten-0"
                        >{{ item.location }}, {{ item.city }}</span
                      >
                    </div>
                  </v-list-item-title>
                  <v-list-item-title>
                    <div class="row mt-4">
                      <div class="col-md-12">
                        <v-btn
                          @click="
                            deletevideo({
                              videoId: item.videoId,
                              createTimeStamp: item.createTimeStamp,
                            })
                          "
                          text
                          class="deep-orange white--text v-btn v-btn--has-bg theme--light v-size--default v-btn v-btn--has-bg theme--light v-size--default mt-4 v-btn v-btn--has-bg theme--light v-size--default"
                        >
                          <v-icon class="">mdi-delete-outline</v-icon> Delete
                        </v-btn>
                      </div>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </div>
              <v-divider />
              <!-- <v-divider :inset="item.inset" /> -->
            </div>
          </v-list-item>
        </template>
      </v-container>

      <v-row>
        <div
          v-if="videos.length < 0"
          three-line
          style="text-align: center"
          class="mt-4"
        >
          No videos available
        </div>
        <v-col cols="12" sm="12" md="12" lg="12">
          <infinite-loading :identifier="infiniteId" @infinite="getVideos">
            <div slot="spinner">
              <v-progress-circular
                indeterminate
                color="deep-orange"
              ></v-progress-circular>
            </div>
            <div slot="no-results"></div>
            <span slot="no-more"></span>
            <div slot="error" slot-scope="{ trigger }">
              <v-alert prominent type="error">
                <v-row align="center">
                  <v-col class="grow">
                    <div class="title">Error!</div>
                    <div>
                      Something went wrong, but don’t fret — let’s give it
                      another shot.
                    </div>
                  </v-col>
                  <v-col class="shrink">
                    <v-btn @click="trigger">Take action</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </div>
          </infinite-loading>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import VideoService from "@/services/VideoService";
import InfiniteLoading from "vue-infinite-loading";

export default {
  data: () => ({
    deleteLoading: false,
    loading: false,
    loaded: false,
    errored: false,
    avatar: "",
    name: "",
    title: "",
    subtitle: "",
    buttonText: "",
    videos: [],
    isVideoPlaying: false,
    infiniteId: +new Date(),
    page: "",
    options: {
      fullscreen: {
        enabled: false,
        fallback: true,
        iosNative: false,
        container: null,
      },
    },
  }),
  // created() {
  //   this.getVideos();
  // },
  watch: {
    searchData(data) {
      this.dataSearch = data;
      // this.infiniteId += 1;
      this.videos = [];
      this.page = "";
    },
  },

  methods: {
    async deletevideo(videoId) {
      await VideoService.deletevideo(videoId).then((resp) => {
        console.log(resp);
        // alert(resp.data.body)
        this.$fire({
          text: "Video Deleted Successfully!",
          type: "success",
          timer: 3000,
        }).then((r) => {
          console.log(r);
          this.videos = [];
          this.getVideos();
        });
        //  alert('Video Deleted Successfully!')
        // }).catch((err) => {
        //   console.log(err)
      });
    },

    async getVideos($state) {
      if (!this.loaded) {
        this.loading = true;
      }
      const videos = await VideoService.getMyVideoList({ page: this.page })
        .catch((err) => {
          console.log(err);
          this.errored = true;
          $state.complete();
        })
        .finally(() => (this.loading = false));
      if (!videos) return;
      this.videos.push(...videos.data.data);

      if (videos.data.LastEvaluatedKey) {
        if (videos.data.LastEvaluatedKey) {
          this.page = videos.data.LastEvaluatedKey;
        }
        $state.loaded();
        this.loaded = true;
      } else {
        this.page = "";
        $state.complete();
      }
    },
    getVideoOptions(item, item2) {
      let mp4OrNot = item.substr(-3);
      return {
        height: "210",
        autoplay: false,
        responsive: true,
        fluid: false,
        source: {
          id: item.videoId,
          type: mp4OrNot == "mp4" ? "video/mp4" : "application/x-mpegURL",
          // "video/mp4" ||
          // "video/x-flv" ||
          // "application/x-mpegURL" ||
          // "video/quicktime" ||
          // "video/x-msvideo",
          src: item,
          responsive: true,
        },
        poster: item2.Gallery.thumbNailsUrl
          ? item2.Gallery.thumbNailsUrl
          : null,
      };
    },
    onVideoPlay() {
      console.log(this.isVideoPlaying);
      this.isVideoPlaying = true;
    },
    onVideoPause() {
      this.isVideoPlaying = false;
    },
    // getBaseUrl(url) {
    //   const parts = url.split("?");
    //   return parts[0];
    // },
  },
  computed: {
    isFirstVideo() {
      return this.currentIndex === 0;
    },
    isLastVideo() {
      return this.currentIndex === this.video.length - 1;
    },
  },
  // mounted() {
  //   this.getVideos();
  // },
  components: {
    InfiniteLoading,
  },
};
</script>

<style lang="scss">
.v-application a {
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 760px) {
  .myvideo > .video-container {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }

  .carousel-container.video-player-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .myvideo > .video-js.vjs-custom-skin .vjs-big-play-button {
    margin-left: -25% !important;
  }
}

@media (max-width: 480px) {
  .myvideo > .video-js.vjs-custom-skin .vjs-big-play-button {
    margin-left: -10% !important;
    margin-top: -15% !important;
  }
}
.myvideo > .video-js.vjs-custom-skin .vjs-big-play-button {
  margin-left: -15% !important;
}
.video-js .vjs-big-play-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 2.2em;
}
</style>
<style scoped>
.text-center-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the height of the container to occupy full viewport height */
}
.v-list-item {
  display: block;
}
</style>
