<template>
  <div id="notification" class="pa-4 pl-0">
    <!-- <v-container fluid> -->

    <template v-if="loading">
      <div class="text-center-loading">
        <v-progress-circular
          indeterminate
          color="#ff5722"
          size="64"
          class="ma-4"
        ></v-progress-circular>
        <div>
          <span>Loading...</span>
        </div>
      </div>
    </template>

    <template>
      <v-container fluid>
        <h2>Tagged Properties</h2>
        <template v-for="(item, index) in videos">
          <v-list-item :key="index">
            <div class="row mb-4 mt-4">
              <div class="col-md-4 col-sm-12 text-center">
                <v-list-item-title :height="100" :width="100">
                  <v-responsive
                    style="border-radius: 10px"
                    class="video-container"
                  >
                    <!-- <v-carousel
                    v-if="item.Gallery"
                    :continuous="false"
                    hide-delimiters
                    class="carousel-container"
                  > -->
                    <!-- <v-carousel-item
                      v-for="(video, index) in item.Gallery?.Videos"
                      :key="index"
                    > -->

                    <div
                      v-if="item.Gallery?.Videos && item.Gallery?.Videos.length"
                    >
                      <video-player
                        :options="
                          getVideoOptions(item.Gallery?.Videos[0], item)
                        "
                        class="video-player-container"
                      >
                        <video
                          class="video-player-container"
                          width="100"
                          preload="metadata"
                          crossorigin
                          controls="controls"
                          playsinline
                          :options="options"
                        ></video>
                      </video-player>
                    </div>
                    <v-img
                      v-else
                      class="video-player-container"
                      :src="require('../../assets/no-video.webp')"
                    ></v-img>
                    <!-- </v-carousel-item> -->
                    <!-- <v-carousel-item
                      v-for="(image, index) in item.Gallery?.Images"
                      :key="index"
                    >
                      <v-img v-if="image" :src="image"></v-img>
                      <v-img
                        v-else
                        :src="require('../../assets/no-video.webp')"
                      ></v-img>
                    </v-carousel-item>
                    <v-btn
                      v-if="!isFirstVideo && !isVideoPlaying"
                      class="custom-prev-button video-player-container"
                      icon
                      @click="prevCarousel"
                    >
                      <img :src="require('../../assets/previous-button.png')" />
                    </v-btn>
                    <v-btn
                      v-if="!isLastVideo && !isVideoPlaying"
                      class="custom-next-button video-player-container"
                      icon
                      @click="nextCarousel"
                    >
                      <img :src="require('../../assets/next-button.png')" />
                    </v-btn>
                  </v-carousel>
                  <v-carousel
                    v-else
                    :continuous="false"
                    hide-delimiters
                    class="carousel-container"
                  >
                    <v-carousel-item>
                      <v-img
                        height="100%"
                        cover
                        :src="require('../../assets/no-video.webp')"
                      ></v-img>
                    </v-carousel-item>
                  </v-carousel> -->
                  </v-responsive>
                  <!-- <v-responsive>
                  <vue-plyr v-if="item.Gallery?.Videos">
                    <video controls crossorigin playsinline>
                      <source
                      :data-poster="item.Gallery.Images[0]"
                        :id="item.createTimeStamp"
                        :src="item.Gallery?.Videos[0]"
                        type="video/mp4"
                      />
                    </video>
                  </vue-plyr>
                  <v-img
                    v-else
                    height="100%"
                    cover
                    :src="require('../../assets/no-video.webp')"
                  ></v-img>
                </v-responsive> -->
                </v-list-item-title>
              </div>
              <div class="col-md-8 col-sm-12">
                <v-list-item-content class="mt-0 pt-0">
                  <v-list-item-title>
                    <div class="text--primary mt-0 text-body-2">
                      Posted By :
                      <span class="grey--text text--lighten-0">
                        {{ item.user_name }}
                      </span>
                    </div>
                  </v-list-item-title>
                  <v-list-item-title>
                    <div class="text--primary mt-2 text-body-2">
                      Title :
                      <span class="grey--text text--lighten-0">{{
                        item.title
                      }}</span>
                    </div>
                  </v-list-item-title>
                  <v-list-item-title>
                    <div class="text--primary mt-2 text-body-2">
                      Location :
                      <span class="grey--text text--lighten-0"
                        >{{ item.location }}, {{ item.city }}</span
                      >
                    </div>
                  </v-list-item-title>
                  <v-list-item-title>
                    <div class="d-flex flex-wrap mt-5">
                      <div>
                        <v-btn
                          text
                          class="deep-orange--text pl-0 mr-3 email-wrap"
                          target="_blank"
                          :href="`mailto:${item.userId}`"
                        >
                          <v-icon>mdi-email-outline</v-icon>
                          <span
                            class="text-black text-lowercase email-wrapper"
                            >{{ item.userId }}</span
                          >
                        </v-btn>
                      </div>
                      <div>
                        <v-btn text class="deep-orange--text" target="_blank">
                          <v-icon>mdi-whatsapp</v-icon>
                          <span class="text-black">{{
                            item.user_phone
                              ? item.user_phone
                              : " Not Available "
                          }}</span>
                        </v-btn>
                      </div>
                    </div>
                  </v-list-item-title>
                  <!-- <v-list-item-title>
                    <div class="d-flex flex-wrap mt-5">
                      <div class="email-address-wrap">
                        <v-btn
                          text
                          class="deep-orange--text pl-0 mr-3"
                          target="_blank"
                          :href="`mailto:${item.userId}`"
                        >
                          <v-icon>mdi-email-outline</v-icon>
                          <span class="text-wrap">
                            <div
                              class="b"
                              style="word-wrap: break-word; width: 69%"
                            >
                              kjhasldjfh#kjhdskfjhkj@fhaskdnfkjhasdf.com
                            </div>

                          </span>
                        </v-btn>
                      </div>
                      <div>
                        <v-btn text class="deep-orange--text" target="_blank">
                          <v-icon>mdi-whatsapp</v-icon>
                          <span class="text-black">
                            {{
                              item.user_phone
                                ? item.user_phone
                                : "Not Available"
                            }}
                          </span>
                        </v-btn>
                      </div>
                    </div>
                  </v-list-item-title> -->
                </v-list-item-content>
              </div>
              <v-divider :inset="item.inset" />
            </div>
          </v-list-item>
        </template>

        <div
          v-if="videos.length < 0"
          three-line
          style="text-align: center"
          class="mt-4"
        >
          No Tagged Properties available
        </div>
      </v-container>
    </template>
  </div>
</template>

<script>
import VideoService from "@/services/VideoService";

export default {
  data: () => ({
    loading: false,
    loaded: false,
    errored: false,
    deleteLoading: false,
    avatar: "",
    name: "",
    title: "",
    subtitle: "",
    buttonText: "",
    videos: [],
    options: {
      fullscreen: {
        enabled: false,
        fallback: true,
        iosNative: false,
        container: null,
      },
    },
  }),
  // created() {
  // this.getVideos();
  // },

  methods: {
    async getVideos($state) {
      if (!this.loaded) {
        this.loading = true;
      }
      const videos = await VideoService.getTaggedVideo()
        .catch((err) => {
          console.log(err);
          this.errored = true;
          $state.complete();
        })
        .finally(() => (this.loading = false));
      if (!videos) return;
      console.log(videos);
      this.videos.push(...videos.data.Items);
    },
    getVideoOptions(item, item2) {
      let mp4OrNot = item.substr(-3);
      return {
        height: "210",
        autoplay: false,
        responsive: true,
        fluid: false,
        source: {
          id: item.videoId,
          type: mp4OrNot == "mp4" ? "video/mp4" : "application/x-mpegURL",
          // "video/mp4" ||
          // "video/x-flv" ||
          // "application/x-mpegURL" ||
          // "video/quicktime" ||
          // "video/x-msvideo",
          src: item,
          responsive: true,
        },
        poster: item2.Gallery.thumbNailsUrl
          ? item2.Gallery.thumbNailsUrl
          : null,
      };
    },
    getBaseUrl(url) {
      const parts = url.split("?");
      return parts[0];
    },
  },
  mounted() {
    this.getVideos();
  },
};
</script>

<style lang="scss">
.v-application a {
  color: rgba(0, 0, 0, 0.87);
}

a.deep-orange--text.v-btn.v-btn--text.theme--light.v-size--default {
  padding: 0;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
.tagged > .video-js.vjs-custom-skin .vjs-big-play-button {
  margin-left: -15% !important;
}
@media (max-width: 768px) {
  .tagged > .video-js.vjs-custom-skin .vjs-big-play-button {
    margin-left: -25% !important;
  }
}
@media (max-width: 480px) {
  .email-wrap {
    word-wrap: break-word;
    white-space: normal;
  }
  .email-wrapper {
    word-wrap: break-word;
    white-space: normal;

    width: 290px;
  }
  .tagged > .video-js.vjs-custom-skin .vjs-big-play-button {
    margin-left: -10% !important;
    margin-top: -15% !important;
  }
}
</style>
<style scoped>
.text-center-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the height of the container to occupy full viewport height */
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 0px;
}
.text-center-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the height of the container to occupy full viewport height */
}

@media (max-width: 760px) {
  .tagged > .video-container {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }

  .carousel-container.video-player-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
  }
  .v-list-item {
    display: block;
  }
}
.tagged > .video-js.vjs-custom-skin .vjs-big-play-button {
  top: 50%;
  left: 52% !important;
}
</style>
<style>
.video-js.vjs-custom-skin .vjs-big-play-button {
  background-color: rgb(126 126 126 / 45%) !important;
  border-radius: 100%;
}
.video-js.vjs-custom-skin .vjs-big-play-button {
  background-color: rgba(126, 126, 126, 0.45) !important;
  border-radius: 100%;
}
.vjs-icon-play:before,
.video-js .vjs-big-play-button:before,
.video-js .vjs-play-control:before {
  content: "\f101";
}
.video-js .vjs-big-play-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 2.2em;
}

.v-application .pa-4 {
  padding: 0px !important;
}
</style>
