var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-4 pl-0",attrs:{"id":"notification"}},[_c('h2',[_vm._v("Notification from Potential Clients")]),(_vm.loading)?[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"ma-4",attrs:{"indeterminate":"","color":"#ff5722","size":"64"}}),_vm._m(0)],1)]:[(_vm.items.length > 0)?_c('v-list',{staticClass:"n-notification",attrs:{"three-line":""}},[_vm._l((_vm.items),function(item,index){return [_c('div',{key:index},[(item.header)?_c('v-subheader',{domProps:{"textContent":_vm._s(item.header)}}):_vm._e(),_c('v-list-item',[_c('v-list-item-content',{staticStyle:{"margin-bottom":"-25px"}},[_c('v-list-item-title',{staticStyle:{"margin-bottom":"3px"},domProps:{"innerHTML":_vm._s('<b>From: </b>' + item.user_name)}}),_c('v-list-item-title',{staticStyle:{"white-space":"normal !important"},domProps:{"innerHTML":_vm._s(
                  '<b>' +
                  item.user_name +
                  '</b><span> has shown interest in your video titled <b>' +
                  item.title +
                  '</b>. ' +
                  item.user_name +
                  ' can be contacted at <b>' +
                  item.vewerId +
                  '</b></span>'
                )}})],1)],1),_c('v-divider')],1)]})],2):_c('div',{staticStyle:{"text-align":"center"}},[_vm._v("No notifications available")])],_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Video deleted successfully "),_c('v-btn',{attrs:{"color":"white","text":"","icon":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }